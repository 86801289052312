<template>
  <div class="elite-tabs-wrapper-content">
    <h3 class="mb-4">{{ reseller.name }}</h3>
    <div class="col-12">
      <div class="col-12">
        <div class="organisation-image">
          <img
            v-if="reseller.logo"
            :src="`${reseller.logo}`"
            class="argon-image"
            style="width: 100%"
          />
          <img v-else src="/img/add-image.svg" class="argon-image default" />
        </div>
      </div>
      <div class="col-12">
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.NAME") }}</dt>
          <dd class="col-sm-8">
            {{ reseller.name }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.EXCERPT") }}</dt>
          <dd class="col-sm-8">
            <div v-html="reseller.excerpt"></div>
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.EMAIL") }}</dt>
          <dd class="col-sm-8">
            {{ reseller.email }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">
            {{ $t("COMMON.PHONE") }}
          </dt>
          <dd class="col-sm-8">
            {{
              reseller.phone_type
                ? $t(`COMMON.PHONE_TYPE_${reseller.phone_type}`)
                : null
            }}:
            {{ reseller.phone }}
            {{
              reseller.phone_extension
                ? ` (ext: ${reseller.phone_extension})`
                : null
            }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">
            {{ $t("COMMON.OTHER_PHONES") }}
          </dt>
          <dd class="col-sm-8">
            <span v-for="(line, index) in reseller.other_phones" :key="index">
              {{ $t(`COMMON.PHONE_TYPE_${line.type}`) }}:
              {{ line.phoneNumber }}
              {{ line.extension ? ` (ext: ${line.extension})` : null }} <br />
            </span>
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.ADDRESS") }}</dt>
          <dd class="col-sm-8">
            {{ reseller.address }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.OWNER") }}</dt>
          <dd class="col-sm-8">
            <router-link :to="$objectViewRoute(reseller.owner)">
              {{
                `${reseller.owner.firstname} ${reseller.owner.lastname} - ${reseller.owner.email}`
              }}
            </router-link>
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd class="col-sm-8" v-if="reseller.created_at">
            {{ $formatDate(reseller.created_at) }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.UPDATED_AT") }}</dt>
          <dd class="col-sm-8" v-if="reseller.updated_at">
            {{ $formatDate(reseller.updated_at) }}
          </dd>
        </dl>
      </div>
    </div>
    <div class="mb-4 mt-4">
      <h3>{{ $t("RESELLERS.CONFIG_MANAGER") }}</h3>
    </div>
    <div class="col-12 config-manager">
      <div class="col-3">
        <img
          v-if="reseller.config_manager_app_logo"
          :src="`${reseller.config_manager_app_logo}`"
          class="argon-image"
          style="width: 100%"
        />
        <img v-else src="/img/placeholder.jpg" class="argon-image" />
      </div>
      <div class="col-9">
        <dl class="row">
          <dt class="col-sm-4">
            {{ $t("RESELLERS.CONFIG_MANAGER_APP_NAME") }}
          </dt>
          <dd class="col-sm-8">
            {{ reseller.config_manager_app_name }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">
            {{ $t("RESELLERS.CONFIG_MANAGER_URL_REGEX") }}
          </dt>
          <dd class="col-sm-8">
            {{ reseller.config_manager_url_regex }}
          </dd>
        </dl>
      </div>
    </div>

    <div class="mb-4 mt-4"></div>

    <div class="col-12">
      <div class="col-3"></div>
      <div class="col-9">
        <dl class="row">
          <dd class="col-sm-12">
            <base-button
              class="elite-button add"
              icon
              size="sm"
              v-if="$currentUserCan($permissions.PERM_EDIT_RESELLERS)"
              @click="generateToken"
            >
              <span class="btn-inner--icon">
                <i class="far fa-redo"></i>
              </span>
              <span class="btn-inner--text">
                {{ $t("RESELLERS.GENERATE_ACCESS_TOKEN") }}
              </span>
            </base-button>
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "reseller-view-global",

  components: {},

  props: ["reseller"],

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {
    generateToken() {
      this.$emit("onGenerateToken");
    },
  },

  mounted() {},

  watch: {
    reseller(reseller) {},
  },
};
</script>
